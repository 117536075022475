<template>
  <div class="uniform">
    <van-notice-bar color="#f5a429" background="#FFFBE8" wrapable :scrollable="false" class="font12"> {{
      $t("uniform.Purchasing_Notice") }} </van-notice-bar>
    <div v-if="Object.keys(orderList).length != 0" style="padding-bottom: 60px">
      <div v-for="(list, key, index) in orderList" :key="index" class="uniformList">
        <div class="flex align-items" v-if="childrenList[key]">
          <van-image round width="44px" height="44px" :src="childrenList[key].avatar" class="avatar-1 mr-10" />
          <div class="item-1-1 flex1">
            <div class="color3 font14">{{ childrenList[key].name }}</div>
            <div class="color6 font12 pt-3">{{ childrenList[key].class_name }}</div>
          </div>
          <!-- <img src="@/assets/shipping.png" alt="Logo" width="20px" height="20px" /> -->
        </div>
        <van-divider />
        <div class="listShop flex align-items" v-for="(_item, indexs) in list.list" :key="indexs">
          <img :src="_item.img" alt="" class="img72" />
          <div class="flex1 flex ml-10">
            <div class="flex1 ml-10">
              <div class="color3 font14 fontWeight">{{ _item.title }}</div>
              <div class="font12 color6 mt-5">{{ _item.attr1 }} | {{ _item.attr2 }}</div>
              <div class="flex align-items mt-5">
                <div class="flex1 font14 colorRed fontWeight">¥ {{ _item.unit_price }}</div>
                <div>x{{ _item.stock }}</div>
              </div>
            </div>
          </div>
        </div>
        <van-divider />
        <div class="flex align">
          <div class="font12 color6 flex1">{{ $t("uniform.Subtotal", { x: getNum(orderList[key].list) }) }}</div>
          <div class="flex align">
            <span class="font12 color6">{{ $t('uniform.Total') }}</span> <span class="color3 font12 ml-5">¥</span><span
              class="title">{{ getTotal(orderList[key].list) }}</span>
          </div>
        </div>
      </div>

      <van-submit-bar class="submitBar" :price="prices" :button-text="$t('uniform.Wechat_Pay')" @submit="onSubmit"
        button-color="#4D88D2" :loading="showLoading"> </van-submit-bar>
    </div>
  </div>
</template>

<script>
import storage from "store";
import Vue from "vue";
import { UNIFORM_SHOPPING } from "@/store/mutation-types";
import { Toast, Icon, SubmitBar, Divider } from "vant";
import { creatOrder, JSAPIPa, cancelPay } from "@/api/uniform";
import wx from "weixin-js-sdk";
import { childList } from "@/api/child";

Vue.use(Icon);
Vue.use(Toast);
Vue.use(SubmitBar);
Vue.use(Divider);

export default {
  name: "lunch",
  components: {},
  data() {
    return {
      orderList: {},
      checkAll: false,
      child_id: "",
      uniformList: {},
      childData: {},
      prices: 0,
      showLoading: false,
      shopData: {},
      childrenList: {},
      orderInit: [],
      family_id: ""
    };
  },
  beforeCreate() {
    // document.querySelector("body").setAttribute("style", "background-color:#fff;");
  },
  computed: {
    //总价
  },
  created() {
    childList().then((res) => {
      res.data.forEach((item) => {
        this.childrenList[item.id] = item;
      });
      this.family_id = res.data[0].family_id
      this.shopData = storage.get(UNIFORM_SHOPPING + '_' + this.family_id) || {};
      this.orderInit = this.shopData.order;
      this.orderList = {};
      this.orderInit.forEach((item) => {
        if (this.orderList[item.child_id]) {
          this.orderList[item.child_id].list.push(item);
          this.orderList[item.child_id].total += item.stock * item.unit_price;
        } else {
          this.orderList[item.child_id] = { total: 0, list: [] };
          this.orderList[item.child_id].list.push(item);
          this.orderList[item.child_id].total = item.stock * item.unit_price;
        }
      });
      let total = 0;
      for (var i = 0; i < this.orderInit.length; i++) {
        total += this.orderInit[i].stock * this.orderInit[i].unit_price;
      }
      this.prices = Math.round(Number(total) * 100);
    });
  },
  methods: {
    abs(val) {
      //金额转换 分->元 保留2位小数 并每隔3位用逗号分开 1,234.56
      var str = (val / 100).toFixed(2) + "";
      var intSum = str.substring(0, str.indexOf(".")).replace(/B(?=(?:d{3})+$)/g, ","); //取到整数部分
      var dot = str.substring(str.length, str.indexOf(".")); //取到小数部分搜索
      var ret = intSum + dot;
      return ret;
    },
    getTotal(list) {
      let total = 0;
      for (let i = 0; i < list.length; i++) {
        total += list[i].stock * list[i].unit_price;
      }
      return this.abs(Math.round(Number(total) * 100));
    },
    getNum(list) {
      let total = 0;
      for (let i = 0; i < list.length; i++) {
        total += list[i].stock;
      }
      return total;
    },
    onSubmit() {
      var product = {};
      for (let key in this.orderList) {
        product[key] = [];
        for (let i = 0; i < this.orderList[key].list.length; i++) {
          product[key].push({
            paid: this.orderList[key].list[i].paid,
            num: this.orderList[key].list[i].stock,
            pid: this.orderList[key].list[i].pid,
          });
        }
      }
      this.showLoading = true;
      creatOrder({ product: product })
        .then((order) => {
          if (order.code == 0) {
            JSAPIPa({ order_id: order.data.order_id, invoice_id: order.data.invoice_id })
              .then((res) => {
                let that = this;
                wx.config({
                  // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                  appId: res.data.jsapiParams.appId,
                  jsApiList: ["chooseWXPay"], // 必填，需要使用的JS接口列表
                });
                wx.ready(function () {
                  wx.chooseWXPay({
                    appId: res.data.jsapiParams.appId, //公众号ID，由商户传入
                    timestamp: res.data.jsapiParams.timeStamp, //时间戳，自1970年以来的秒数
                    nonceStr: res.data.jsapiParams.nonceStr, //随机串
                    package: res.data.jsapiParams.package,
                    signType: res.data.jsapiParams.signType, //微信签名方式：
                    paySign: res.data.jsapiParams.paySign, //微信签名
                    success() {
                      // 支付成功回调
                      if (that.orderInit.length != 0) {
                        that.orderInit.forEach((item) => {
                          if (that.shopData.shopping[item.child_id]) {
                            Vue.delete(that.shopData.shopping[item.child_id], item.paid);
                            if (Object.keys(that.shopData.shopping[item.child_id]).length === 0) {
                              Vue.delete(that.shopData.shopping, item.child_id);
                            }
                          }
                        });
                        const shop = {
                          shopping: that.shopData.shopping,
                          order: [],
                        };
                        storage.set(UNIFORM_SHOPPING + '_' + that.family_id, shop);
                      }
                      Toast.success(that.$t("uniform.Payment_successful"));
                      that.$router.push({
                        name: 'UnifromOrder',
                      });
                      that.showLoading = false;
                    },
                    cancel() {
                      Toast.fail(that.$t("uniform.Cancel_Payment"));
                      that.showLoading = false;
                      that.getCancelPay(order.data.invoice_id);
                    },
                    fail() {
                      that.getCancelPay(order.data.invoice_id);
                      Toast.fail(that.$t("uniform.Payment_Failed"));
                      that.showLoading = false;
                      // 支付失败回调
                    },
                  });
                });
              })
              .catch(() => {
                this.showLoading = false;
              });
          } else {
            this.showLoading = false;
          }
        })
        .catch(() => {
          this.showLoading = false;
        });
    },
    getCancelPay(invoice_id) {
      cancelPay({ invoice_ids: invoice_id }).then((invoice) => {
        console.log(invoice);
      });
    },
  },
};
</script>

<style scoped lang="less">
.img72 {
  width: 72px;
  height: 72px;
  border-radius: 8px;
  object-fit: cover;
}

.uniformList {
  background: #fff;
  padding: 12px;
  margin: 10px 8px;
}

.align-items {
  align-items: center;
}

.colorRed {
  color: #ee0a24;
}

.fontWeight {
  font-weight: 500;
}

.word-break {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.word-break2 {
  overflow: hidden; //超出文本隐藏
  text-overflow: ellipsis; ///超出部分省略号显示
  display: -webkit-box; //弹性盒模型
  -webkit-box-orient: vertical; //上下垂直
  -webkit-line-clamp: 2; //自定义行数
}

.goods-card {
  margin: 0;
}

.delete-button {
  height: 100%;
}

.van-submit-bar__button--danger {
  background-color: #4d88d2;
}

.itemList {
  margin-bottom: 60px;
}

.listShop {
  padding: 16px 0;
}

.title {
  font-size: 16px;
  color: #323233;
  font-weight: 500;
  margin-left: 5px
}

.align {
  align-items: center;
}

.uniform {
  overflow-y: scroll;
  height: 100vh;
}

.uniform::-webkit-scrollbar {
  display: none;
}
</style>
<style>
.submitBar .van-submit-bar__text span {
  margin-left: 5px;
}
</style>
