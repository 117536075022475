import Vue from 'vue';

export function TeamsList(params) {
  return Vue.axios({
    url: '/api/parent/schoolTeams/list',
    method: 'get',
    params: params
  })
}
export function TeamsHome(params) {
  return Vue.axios({
    url: '/api/parent/schoolTeams/home',
    method: 'get',
    params: params
  })
}

export function schoolTeamsDetailPreview(params) {
  return Vue.axios({
    url: '/api/preview/schoolTeams/detail',
    method: 'get',
    params: params
  })
}

export function schoolTeamsDetail(params) {
  return Vue.axios({
    url: '/api/parent/schoolTeams/detail',
    method: 'get',
    params: params
  })
}

export function TeamsHomePreview(params) {
  return Vue.axios({
    url: '/api/preview/schoolTeams/home',
    method: 'get',
    params: params
  })
}

export function TeamsListPreview(params) {
  return Vue.axios({
    url: '/api/preview/schoolTeams/list',
    method: 'get',
    params: params
  })
}