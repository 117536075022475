import { render, staticRenderFns } from "./NoticeContent.vue?vue&type=template&id=773c27e7&scoped=true&"
import script from "./NoticeContent.vue?vue&type=script&lang=js&"
export * from "./NoticeContent.vue?vue&type=script&lang=js&"
import style0 from "./NoticeContent.vue?vue&type=style&index=0&lang=less&"
import style1 from "./NoticeContent.vue?vue&type=style&index=1&id=773c27e7&lang=less&scoped=true&"
import style2 from "./NoticeContent.vue?vue&type=style&index=2&lang=less&"
import style3 from "./NoticeContent.vue?vue&type=style&index=3&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "773c27e7",
  null
  
)

export default component.exports