<template>
    <div v-if="dataInfo.title">
        <van-notice-bar left-icon="volume-o" :text="$t('notice.preview_model')" v-if="$route.name == 'previewSchoolTeams'" />
        <div class="gradient-background">
            <img :src="dataInfo.poster_url" alt="Your Image" />
            <div class="titleInfo">
                <div class="title">{{ dataInfo.title }}</div>
                <div>
                    <span class="lableTag">{{ dataInfo.category }}</span>
                    <span class="lableTag">{{ getVal(dataInfo.grade_group, "grade_group") }}</span>
                    <span class="lableTag">{{ getVal(dataInfo.type, "team_type") }}</span>
                    <!-- <span class="lableTag">{{ getVal(dataInfo.recruitment_status, "recruitment_status") }}</span> -->
                </div>
            </div>
        </div>
        <div class="content">
            <div v-if="dataInfo.member_list.members.length">
                <div class="title_1">
                    <div class="title_text">成员</div>
                    <span class="bg">
                        <span class="yellow"></span>
                        <span class="blue"></span>
                    </span>
                </div>
                <div class="member">
                    <div v-for="(list, index) in dataInfo.member_list.members" class="flex memberFlex" :key="index">
                        <div class="memberImg">
                            <img :src="dataInfo.member_list.child_info[list.child_id].avatar" />
                        </div>
                        <div class="flex1">
                            <div class="font14 mt-10">{{ dataInfo.member_list.child_info[list.child_id].name }}</div>
                            <div class="font12 class">{{ list.class_title }}</div>
                            <span class="position" v-if="list.position != ''"><van-icon name="star" class="mr-5" />{{
                                getVal(list.position, "position") }}</span>
                        </div>
                        <span v-if="list.uniform_number != ''" class="uniform_number">
                            <img src="@/assets/sportsgarment.png" alt="">
                            <span>{{ list.uniform_number }}</span>
                        </span>

                    </div>
                </div>
            </div>
            <div class="title_1 mt-10">
                <div class="title_text">校队介绍</div>
                <span class="bg">
                    <span class="yellow"></span>
                    <span class="blue"></span>
                </span>
            </div>
            <div class="mt-20">
                <div v-html="dataInfo.intro" class="contentHtml" @click="textClick"></div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { NoticeBar, Divider, Toast, Dialog, ImagePreview } from "vant";

import { schoolTeamsDetailPreview, schoolTeamsDetail } from "@/api/schoolTeams";

Vue.use(NoticeBar);
Vue.use(Divider);
Vue.use(Toast);
Vue.use(Dialog);
export default {
    name: "TeamContent",
    components: {},
    data() {
        return {
            dataInfo: {},
        };
    },
    beforeCreate() {
        document.querySelector("body").setAttribute("style", "background-color:#111265;");
    },
    beforeDestroy() {
        // document.querySelector('body').removeAttribute('style')
    },
    created() {
        this.getInit();
    },
    watch: {},
    mounted() { },
    methods: {
        getInit() {
            if (this.$route.name == "previewSchoolTeams") {
                schoolTeamsDetailPreview({ teamId: this.$route.params.id }).then((res) => {
                    if (res.code == 0) {
                        this.dataInfo = res.data;
                    }
                });
            } else {
                schoolTeamsDetail({ teamId: this.$route.params.id }).then((res) => {
                    if (res.code == 0) {
                        this.dataInfo = res.data;
                    }
                });
            }
        },
        getVal(id, list) {
            let item = this.dataInfo.config[list].filter((i) => i.key == id);
            return item[0].value;
        },
        textClick(e) {
            if (e.target.tagName.toUpperCase() === "IMG") {
                let html = this.dataInfo.intro.replace(/&amp;/g, '&');
                const imgTags = html.match(/<img[^>]+>/g);
                var ret = [];
                if (imgTags) {
                    imgTags.map((url) => {
                        ret.push(url.match(/\ssrc=['"](.*?)['"]/)[1]);
                    });
                }
                let index = ret.indexOf(decodeURIComponent(e.target.src));
                ImagePreview({
                    images: ret,
                    closeable: true,
                    startPosition: index,
                });
            }
        },
    },
};
</script>
<style lang="less" scoped>
.gradient-background {
    position: relative;
    display: inline-block;
    padding-top: 50px;

    img {
        display: block;
        width: 100%;
    }

    .titleInfo {
        position: absolute;
        top: 20px;
        width: 100%;
        text-align: center;

        .title {
            color: #fff;
            font-size: 24px;
            font-weight: bold;
            padding-bottom: 6px;
        }

        .lableTag {
            background: #eaebff;
            border-radius: 2px;
            padding: 4px 6px;
            width: 24px;
            font-size: 12px;
            color: #111265;
            margin-right: 6px;
        }
    }
}

.gradient-background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, #111265 0%, #111265 20%, rgba(39, 41, 158, 0) 50%);
}


.content {
    background: #111265;
    padding: 24px 16px;
    color: #ffffff;

    .title_1 {
        font-weight: bold;
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
        position: relative;
        display: inline-block;

        .title_text {
            position: relative;
            z-index: 99;
            font-weight: bold;
        }
    }

    .member {
        margin-top: 24px;

        .memberFlex {
            margin-bottom: 16px;
            display: flex;
            align-items: top;

            .memberImg {
                position: relative;

                img {
                    width: 72px;
                    height: 54px;
                    border-radius: 6px;
                    object-fit: cover;
                }

                span {
                    position: absolute;
                    right: -11px;
                    background-image: url("../assets/teamMember.png");
                    background-size: 22px 22px;
                    background-repeat: no-repeat;
                    background-position: bottom right;
                    display: inline-block;
                    width: 22px;
                    height: 22px;
                    top: -10px;
                    text-align: center;
                    line-height: 22px;
                    font-size: 12px;
                }


            }

            .flex1 {
                margin-left: 12px;

                .class {
                    opacity: 0.7;
                    margin-top: 6px;
                }

                .position {
                    padding: 4px 8px;
                    background: #476bf5;
                    border-radius: 16px;
                    font-size: 12px;
                    line-height: 12px;
                    height: 12px;
                    display: inline-block;
                    margin-top: 8px;
                }
            }



            .uniform_number {
                font-family: "Regular" !important;
                position: relative;

                img {
                    width: 65px;
                }

                span {
                    position: absolute;
                    text-align: center;
                    left: 50%;
                    font-size: 20px;
                    color: #FFB752;
                    color: #FFB752;
                    width: 20px;
                    text-align: center;
                    margin-left: -10px;
                    line-height: 80px;
                }
            }
        }
    }
}

.bg {
    position: absolute;
    left: 50%;
    margin-left: -8px;
    height: 10px;
    bottom: -5px;
    z-index: 9;

    .yellow {
        width: 10px;
        height: 10px;
        background: #ffc53c;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        top: 0;
        z-index: 99;
    }

    .blue {
        width: 10px;
        height: 10px;
        background: #476bf5;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        left: 6px;
    }
}
</style>
<style lang="less">
.contentHtml {
    font-size: 14px;
    line-height: 22px;

    img {
        width: 100%;
        height: auto
    }
}
</style>
